function defaultMapFn(word: string, index: number): string {
  const fullname = word.charAt(0).toUpperCase() + word.slice(1);
  return index ? `${fullname.substring(0, 1)}.` : fullname;
}

export function getFullNameAbbreviation(
  lastName: string,
  name: string,
  patronymic: string,
  email?: string,
  separator = ' ',
  mapFn = defaultMapFn,
) {
  return !lastName || !name
  ? email ?? ''
  : [lastName, name, patronymic]
      .filter((item) => !!item)
      .map(mapFn)
      .join(separator);
}
